import React,  {useState} from 'react'
import Layout from '../components/layout'
import { text, blue } from '../components/common.module.css'
import Modal from '../components/shared/modal'

const ReportGenerator = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <div style={{  maxwidth:"100%", overflow: "hidden"}}>
      <Layout pageTitle="Audit Report Generator" open={open} setOpen={setOpen}>
          <div className={`${text} ${blue}`} style={{ fontSize: "24px", margin: "15vh", marginLeft: "10vw", marginRight: "10vw", textAlign: "center", lineHeight: "30px"}}>
            Hi there!!<br/><br/>
            We are working on the Report Generator. Come back again!!
          </div>
      </Layout>
      <Modal open={open} setOpen={setOpen} />
    </div>
  )
}

export default ReportGenerator